import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchMostReadArticles } from '../../store/mostReadArticles'
import NoResults from '../static/icons/no-search-results-v2.svg'
import NoImage from '../static/no-image.png'
import { Articles } from 'ion-article-cmp'
import { ImageOrNoImage } from 'ion-image'
import { Link } from 'react-router-dom'
import { Mobile, MobileElse } from 'ion-media'

const MostReadArticles = ({ articles, authorName, noImage, moreButton }) => {
  return (
    <>
      {articles.map((article) => {
        return (
          <article key={article.contentKey} role='contentinfo' aria-label='article' itemScope='' itemType='https://schema.org/NewsArticle'>
            <div className='article-list-img'>
              <Link to={'/' + article.getCanonicalUri()}>
                <Mobile>
                  <ImageOrNoImage alt={article.caption} image={article.getImageObject()} width={350} noImage={noImage} />
                </Mobile>
                <MobileElse>
                  <ImageOrNoImage alt={article.caption} image={article.getImageObject()} width={680} noImage={noImage} />
                </MobileElse>
              </Link>
            </div>
            <div className='content'>
              <small className='meta'><Link to={'/' + article.getSectionId()}>{article.sectionLabel}</Link></small>
              <h3><Link to={'/' + article.getCanonicalUri()}>{article.headline}</Link></h3>
            </div>
          </article>
        )
      })}
      {moreButton}
    </>
  )
}

class NotFound extends Component {
  constructor (props) {
    super(props)
    const { staticContext } = props
    if (staticContext) {
      staticContext.is404 = true
    }
  }

  render () {
    // console.log('404', this.props)
    return (
      <>
        <div className='main-content'>
          <div className='wrapper no-results'>
            <img src={NoResults} alt='404 Page not found' />
            <h1>404 Page not found</h1>
          </div>
        </div>
        <div className='wrapper'>
          <div className='search-page' itemType='http://schema.org/ItemList'>
            <h1><span>Most Read</span></h1>
            <Articles
              className='search-results' component={MostReadArticles} page0Limit={8} pageSize={8}
              fetchArticles={this.props.fetchMostReadArticles} noImage={NoImage} articles={this.props.articles}
              notFound={() => (<div>Oops! No articles found.</div>)} fetchMoreArticles={() => {}}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({ articles: state.mostReadArticles })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchMostReadArticles }, dispatch)

// export default NotFound
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotFound))
