import React, { Component } from 'react'
import { squareImage } from 'ion-article'
import Lightbox from 'react-18-image-lightbox'
import NoImage from '../static/no-image.png'
import PropTypes from 'prop-types'

export default class ArticleGallery extends Component {
  constructor (props) {
    super(props)
    this.state = {
      photoIndex: 0,
      isOpen: false
    }
  }

  render () {
    const gallery = this.props.gallery
    return (
      <div className='article-gallery'>
        {gallery.map((image, index) => {
          return (
            <a onClick={() => this.setState({ isOpen: true, photoIndex: index })} key={index}>
              <img alt={'Gallery image ' + index} title={gallery[index].caption} src={squareImage(200, image, NoImage)} />
            </a>
          )
        })}
        {this.state.isOpen && (
          <div id='lighbox'>
            <Lightbox
              mainSrc={gallery[this.state.photoIndex].url}
              nextSrc={gallery[(this.state.photoIndex + 1) % gallery.length].url}
              prevSrc={gallery[(this.state.photoIndex + gallery.length - 1) % gallery.length].url}
              imageCaption={gallery[(this.state.photoIndex + gallery.length) % gallery.length].caption}
              enableZoom={false}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + gallery.length - 1) % gallery.length
                })}
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + 1) % gallery.length
                })}
            />
          </div>
        )}
      </div>
    )
  }
}

ArticleGallery.propTypes = {
  gallery: PropTypes.array.isRequired
}

ArticleGallery.defaultProps = {
  gallery: []
}
