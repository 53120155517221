import React, { Fragment } from 'react'
// import { squareImage } from 'ion-article'

const RelatedArticles = ({ relatedArticles, abstract }) => {
  // console.log(relatedArticles)
  return (
    <>
      <h2><span>More on this</span></h2>
      <ul>
        {relatedArticles.map(relatedArticle => (
          <li key={relatedArticle.contentKey}>
            <article key={relatedArticle.id}>
              <a href={relatedArticle.externalUrl}>
                <h3>{relatedArticle.title}</h3>
                <p>{relatedArticle.abstract}</p>
              </a>
            </article>
          </li>
        ))}
      </ul>
    </>
  )
}

export default RelatedArticles
