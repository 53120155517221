import React, { Fragment } from 'react'
import ArticleCard from '../../components/ArticleCard'
import Ad from '../../components/Ad'
import FORMATS from '../../components/AdFormats'
import { Link } from 'react-router-dom'
import { getSectionLabel } from 'ion-sections'
import { Mobile, MobileElse } from 'ion-media'

const mpuRHS = [
  [[1024, 0], [[300, 250], [3, 3], 'fluid']],
  [[640, 0], [[300, 250], [336, 280], [3, 3], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], [3, 3], 'fluid']]
]

const Default = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections }) => {
  return (
    <>
      <h1><span>{sectionLabel}</span></h1>
      <div className='related-sections'>
        {relatedSections && (
          <>
            <p>Related Sections</p>
            <nav>
              <ul>
                {relatedSections.map((relatedSection) => {
                  return (
                    <Fragment key={relatedSection.id}>
                      <li>
                        <Link to={'/' + relatedSection.id}>
                          {(relatedSection.label && (relatedSection.label)) || (getSectionLabel(relatedSection.id))}
                        </Link>
                      </li>
                    </Fragment>
                  )
                })}
              </ul>
            </nav>
          </>
        )}
      </div>
      <div className='sections'>
        {articles.map((article, index) => {
          return (
            <Fragment key={article.contentKey}>
              {((index - 23) / 16) <= -1 && (
                <>
                  <Mobile>
                    <ArticleCard article={article} noImage={noImage} width={371} />
                  </Mobile>
                  <MobileElse>
                    <ArticleCard article={article} noImage={noImage} width={325} />
                  </MobileElse>
                </>
              )}
              {((index - 23) / 16) === -1 && (
                <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${section}`} slotId='MPU-topright' targeting={{ MPU: 'topright' }} collapseEmptyDiv className='advert-sidebar' mapping={mpuRHS} {...FORMATS.mpu} />
              )}
              {((index - 23) / 16) > -1 && (
                <>
                  <Mobile>
                    <ArticleCard article={article} noImage={noImage} width={371} />
                  </Mobile>
                  <MobileElse>
                    <ArticleCard article={article} noImage={noImage} width={325} />
                  </MobileElse>
                </>
              )}
            </Fragment>
          )
        })}
      </div>
      {moreButton}
    </>
  )
}

export default Default
